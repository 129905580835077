<template>
    <div class="yinhang-status">
        <div class="header acea-row row-between-wrapper" ref="header">
            <!-- <div class="black" @click="returnChange()">
                <img src="@assets/images/icon_black.png" alt="" />
            </div> -->
            <p>提交成功</p>
        </div>
        <div>
            <div class="img">
                <img src="@assets/images/icon_suc.png" alt="">
            </div>
            <p style="text-align:center; font-size:15px; color:#333; line-height:25px;">提交成功</p>
        </div>
        <div class="detail-btn acea-row row-between">
            <div class="right pay-done" @click="toPayAliPay">
              <p>完成</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'BankSuccess',
    data() {
        return {

        }
    },
    mounted() {

    },
    methods:{
        toPayAliPay() {
            this.$router.push({
                path:'/'
            })
        }
    }
}
</script>
<style>
.yinhang-status { height:100%; }

.yinhang-status .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
  position:relative;
}
.yinhang-status .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
  margin-top:15px;
}
.yinhang-status .header .black img {
  width: 100%;
  height: 100%;
}
.yinhang-status .header p {
  width: calc(100% - 17px);
  text-align: center;
  color: #333;
  line-height: 0.96rem;
}
.yinhang-status .header p.admin {
  width:auto;
  position: absolute;
  right: 8px;
  top: 0px;
  font-size: 14px;
  color: #666;
}
</style>